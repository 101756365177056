import(/* webpackMode: "eager" */ "/learn-web/apps/learn/containers/AppModalProvider/AppModalProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Header/ScrollIndicator/ScrollIndicator.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/containers/FormProvider/FormProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/containers/HomePage/HomePage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopNavigation"] */ "/learn-web/features/navigation/components/Navigation/desktop/DesktopNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/learn-web/features/navigation/components/Navigation/mobile/MobileNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/learn-web/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/learn-web/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
