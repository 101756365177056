import type React from 'react'
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

import classNames from 'classnames'

import { Link } from '../Link/Link'

import { OverviewCardCTA } from './OverviewCard.CTA/OverviewCard.CTA'
import { OverviewCardDescription } from './OverviewCard.Description/OverviewCard.Description'
import { OverviewCardTitle } from './OverviewCard.Title/OverviewCard.Title'

interface SubComponents {
  Title: typeof OverviewCardTitle
  Description: typeof OverviewCardDescription
  Cta: typeof OverviewCardCTA
}

export interface OverviewCardProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Href to turn the entire card into a link?
   */
  href?: string
  /**
   * Padding size.
   */
  padding?: 'none' | 'sm' | 'md'
}

export const OverviewCard: React.FC<OverviewCardProps> & SubComponents = ({
  className,
  children,
  href,
  padding = 'md',
  ...cardProps
}) => (
  <CardContainer href={href}>
    <div
      className={classNames(
        'border border-neutral-subtle rounded-md group h-full w-full',
        { 'p-5': padding === 'sm' },
        { 'p-6': padding === 'md' },
        {
          'cursor-pointer hover:border-gradient-selected hover:shadow-md': href,
        },
        className,
      )}
      {...cardProps}
    >
      {children}
    </div>
  </CardContainer>
)

OverviewCard.Title = OverviewCardTitle
OverviewCard.Description = OverviewCardDescription
OverviewCard.Cta = OverviewCardCTA

interface CardContainerProps extends PropsWithChildren {
  href: string | undefined
}

const CardContainer: React.FC<CardContainerProps> = ({ href, children }) => {
  return href ? (
    <Link
      className="h-full w-full hover:no-underline [font-weight:400_!important]"
      path={href}
      rel="noreferrer noopener nofollow"
      target={href.startsWith('http') ? '_blank' : '_self'}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}
