'use client'
import { useMemo } from 'react'

import { CWButton } from '@features/common.components/ComponentsWeb/Button'
import {
  CWHeading,
  CWHeadingEyebrow,
  CWHeadingHeader,
  CWHeadingIntro,
} from '@features/common.components/ComponentsWeb/Heading'
import {
  CWIcon,
  CWIconCircle,
} from '@features/common.components/ComponentsWeb/Icon'
import { HeroBanner } from '@features/common.components/HeroBanner'
import { Link } from '@features/common.components/Link'
import { ListCard } from '@features/common.components/ListCard'
import { OverviewCard } from '@features/common.components/OverviewCard'
import { TextLink } from '@features/common.components/TextLink'
import { useFeatureFlags } from '@features/flags.hooks/useFeatureFlags'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import type { IconProps } from '@circlefin/components/lib/Icon'
// eslint-disable-next-line import/extensions
import type { IconCircleProps } from '@circlefin/components/lib/Icon/Icon.Circle/Icon.Circle'
import type { Translate } from 'next-translate'

type GetStartedTopCardProps = {
  href: string
  icon: IconProps['name']
  intent: IconCircleProps['intent']
  title: string
  description: string
  cta: string
}

type GetStartedBottomCardProps = {
  href: string
  title: string
  description: string
  cta: string
}

type ProductsAndFeaturesSectionProps = {
  header: string
  intro: string
  cardsTop: {
    title: string
    href: string
  }[]
  cardsBottom?: {
    title: string
    href: string
  }[]
}

type BuildWithConfidenceCardProps = {
  href: string
  icon: IconProps['name']
  title: string
  description: string
}

const getGetStartedTopCards = (t: Translate): GetStartedTopCardProps[] => [
  {
    href: t`homePage.getStarted.topCards.0.href`,
    icon: 'CodeOutline',
    intent: 'accent-purple',
    title: t`homePage.getStarted.topCards.0.title`,
    description: t`homePage.getStarted.topCards.0.description`,
    cta: t`homePage.getStarted.topCards.0.cta`,
  },
  {
    href: t`homePage.getStarted.topCards.1.href`,
    icon: 'CodeOutline',
    intent: 'accent-blue',
    title: t`homePage.getStarted.topCards.1.title`,
    description: t`homePage.getStarted.topCards.1.description`,
    cta: t`homePage.getStarted.topCards.1.cta`,
  },
  {
    href: t`homePage.getStarted.topCards.2.href`,
    icon: 'LightBulbOutline',
    intent: 'accent-green',
    title: t`homePage.getStarted.topCards.2.title`,
    description: t`homePage.getStarted.topCards.2.description`,
    cta: t`homePage.getStarted.topCards.2.cta`,
  },
  {
    href: t`homePage.getStarted.topCards.3.href`,
    icon: 'BookOpenOutline',
    intent: 'accent-orange',
    title: t`homePage.getStarted.topCards.3.title`,
    description: t`homePage.getStarted.topCards.3.description`,
    cta: t`homePage.getStarted.topCards.3.cta`,
  },
]

const getGetStartedBottomCards = (
  t: Translate,
): GetStartedBottomCardProps[] => [
  {
    href: t`homePage.getStarted.bottomCards.0.href`,
    title: t`homePage.getStarted.bottomCards.0.title`,
    description: t`homePage.getStarted.bottomCards.0.description`,
    cta: t`homePage.getStarted.bottomCards.0.cta`,
  },
  {
    href: t`homePage.getStarted.bottomCards.1.href`,
    title: t`homePage.getStarted.bottomCards.1.title`,
    description: t`homePage.getStarted.bottomCards.1.description`,
    cta: t`homePage.getStarted.bottomCards.1.cta`,
  },
]

const getProductsAndFeaturesSections = (
  t: Translate,
): ProductsAndFeaturesSectionProps[] => [
  {
    header: t`homePage.productsAndFeatures.sections.0.header`,
    intro: t`homePage.productsAndFeatures.sections.0.intro`,
    cardsTop: Array.from({ length: 3 }).map((_, i) => {
      return {
        href: t(`homePage.productsAndFeatures.sections.0.cardsTop.${i}.href`),
        title: t(`homePage.productsAndFeatures.sections.0.cardsTop.${i}.title`),
      }
    }),
    cardsBottom: [3, 4].map((i) => {
      return {
        href: t(`homePage.productsAndFeatures.sections.0.cardsTop.${i}.href`),
        title: t(`homePage.productsAndFeatures.sections.0.cardsTop.${i}.title`),
      }
    }),
  },
  {
    header: t`homePage.productsAndFeatures.sections.1.header`,
    intro: t`homePage.productsAndFeatures.sections.1.intro`,
    cardsTop: Array.from({ length: 1 }).map((_, i) => {
      return {
        href: t(`homePage.productsAndFeatures.sections.1.cardsTop.${i}.href`),
        title: t(`homePage.productsAndFeatures.sections.1.cardsTop.${i}.title`),
      }
    }),
  },
  {
    header: t`homePage.productsAndFeatures.sections.2.header`,
    intro: t`homePage.productsAndFeatures.sections.2.intro`,
    cardsTop: Array.from({ length: 5 }).map((_, i) => {
      return {
        href: t(`homePage.productsAndFeatures.sections.2.cardsTop.${i}.href`),
        title: t(`homePage.productsAndFeatures.sections.2.cardsTop.${i}.title`),
      }
    }),
  },
]

export const HomePage: React.FC = () => {
  const { t } = useTranslation('common')
  const { liquidityWizardEnabled } = useFeatureFlags()
  const buildWithConfidenceCards: BuildWithConfidenceCardProps[] = t(
    'homePage.buildWithConfidence.cards',
    null,
    {
      returnObjects: true,
    },
  )

  const GET_STARTED_TOP_CARDS = getGetStartedTopCards(t)
  const GET_STARTED_BOTTOM_CARDS = getGetStartedBottomCards(t)
  const PRODUCTS_AND_FEATUES_SECTIONS = getProductsAndFeaturesSections(t)
  const BUILD_WITH_CONFIDENCE_CARDS = useMemo(
    () =>
      liquidityWizardEnabled
        ? buildWithConfidenceCards
        : buildWithConfidenceCards.slice(1),
    [buildWithConfidenceCards, liquidityWizardEnabled],
  )

  return (
    <main className="w-full">
      <HeroBanner
        className="flex-col sm:flex-row overflow-hidden xl:rounded-sm xl:mt-4"
        data-testid="hero-banner"
      >
        <HeroBanner.Content
          className="p-6 sm:pl-[49px] sm:py-[44px] max-w-[759px] lg:min-w-[558px]"
          description={t('homePage.header.description')}
          title={t('homePage.header.title')}
        />
        <HeroBanner.Media
          alt={t('homePage.header.title')}
          height={264}
          src="/images/Growth.svg"
          width={359}
        />
      </HeroBanner>

      <div className="p-6 space-y-6">
        <section data-testid="get-started-section">
          <CWHeading variant="section/md">
            <CWHeadingHeader className="type-h-section-md">
              {t('homePage.getStarted.header')}
            </CWHeadingHeader>
          </CWHeading>
          <div className="grid gap-4 grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-4">
            {GET_STARTED_TOP_CARDS.map((card) => (
              <OverviewCard
                key={card.title}
                className="flex flex-col gap-4"
                href={card.href}
              >
                <CWIconCircle intent={card.intent} name={card.icon} />
                <OverviewCard.Title>{card.title}</OverviewCard.Title>
                <OverviewCard.Description className="grow">
                  {card.description}
                </OverviewCard.Description>
                <OverviewCard.Cta>{card.cta}</OverviewCard.Cta>
              </OverviewCard>
            ))}
          </div>

          <div className="grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2 mt-6">
            {GET_STARTED_BOTTOM_CARDS.map((card) => (
              <OverviewCard
                key={card.title}
                className="flex gap-4 items-end justify-between"
                href={card.href}
                padding="sm"
              >
                <div className="h-full">
                  <OverviewCard.Title>{card.title}</OverviewCard.Title>
                  <OverviewCard.Description className="mt-4">
                    {card.description}
                  </OverviewCard.Description>
                </div>
                <OverviewCard.Cta>{card.cta}</OverviewCard.Cta>
              </OverviewCard>
            ))}
          </div>
        </section>

        <section
          className="pt-6 space-y-6"
          data-testid="products-and-features-section"
        >
          <CWHeading variant="section/md">
            <CWHeadingHeader className="type-h-section-md">
              {t('homePage.productsAndFeatures.header')}
            </CWHeadingHeader>
          </CWHeading>

          {PRODUCTS_AND_FEATUES_SECTIONS.map((section) => (
            <div key={section.header}>
              <CWHeading variant="title/md">
                <CWHeadingHeader className="type-h-title-md">
                  {section.header}
                </CWHeadingHeader>
                <CWHeadingIntro className="type-body-base">
                  {section.intro}
                </CWHeadingIntro>
              </CWHeading>

              <div className="flex flex-col gap-4 mt-4">
                <div className="flex flex-wrap gap-4">
                  {section.cardsTop.map((card) => (
                    <div key={card.title}>
                      <OverviewCard href={card.href} padding="sm">
                        <OverviewCard.Title>{card.title}</OverviewCard.Title>
                      </OverviewCard>
                    </div>
                  ))}
                </div>
                {section.cardsBottom && (
                  <div className="flex flex-wrap gap-4">
                    {section.cardsBottom.map((card) => (
                      <div key={card.title}>
                        <OverviewCard href={card.href} padding="sm">
                          <OverviewCard.Title>{card.title}</OverviewCard.Title>
                        </OverviewCard>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </section>

        <OverviewCard
          className="flex flex-col sm:flex-row gap-4 sm:items-end justify-between bg-accent-purple-gradient"
          data-testid="start-building-banner"
        >
          <div>
            <OverviewCard.Title size="lg">
              {t('homePage.startBuildingBanner.title')}
            </OverviewCard.Title>
            <OverviewCard.Description className="mt-3.5">
              {t('homePage.startBuildingBanner.description')}
            </OverviewCard.Description>
          </div>
          <Link
            className="w-fit"
            path={t('homePage.startBuildingBanner.path')}
            type="external"
          >
            <CWButton variant="primary">
              {t('homePage.startBuildingBanner.cta')}
              <CWIcon className="ml-2" name="ArrowNarrowRightSolid" size={24} />
            </CWButton>
          </Link>
        </OverviewCard>

        <section data-testid="build-with-confidence-section">
          <CWHeading variant="title/md">
            <CWHeadingEyebrow className="type-body-xs-bold text-neutral-subtlest">
              {t('homePage.buildWithConfidence.eyebrow')}
            </CWHeadingEyebrow>
            <CWHeadingHeader className="type-h-title-md">
              {t('homePage.buildWithConfidence.header')}
            </CWHeadingHeader>
          </CWHeading>

          <div className="mt-4 flex flex-col sm:flex-row gap-4">
            <HeroBanner className="flex-1 rounded-sm flex items-center justify-center">
              <HeroBanner.Media
                alt={t('homePage.buildWithConfidence.header')}
                className="max-w-[359px]"
                src="/images/Inclusive.svg"
              />
            </HeroBanner>

            <div className="flex-1 flex flex-col gap-4 h-min">
              {BUILD_WITH_CONFIDENCE_CARDS.map((card) => (
                <ListCard
                  key={card.title}
                  description={card.description}
                  href={card.href}
                  icon={card.icon}
                  title={card.title}
                />
              ))}
            </div>
          </div>
        </section>

        <section data-testid="join-community-section">
          <CWHeading variant="title/md">
            <CWHeadingEyebrow className="type-body-xs-bold text-neutral-subtlest">
              {t('homePage.joinCommunity.eyebrow')}
            </CWHeadingEyebrow>
            <CWHeadingHeader className="type-h-title-md">
              {t('homePage.joinCommunity.header')}
            </CWHeadingHeader>
          </CWHeading>

          <div className="mt-4 flex flex-col sm:flex-row gap-4">
            <div className="flex-1 grid gap-4 grid-flow-row grid-cols-1 lg:grid-cols-2 h-min">
              <OverviewCard
                className="flex flex-col"
                href={t('homePage.joinCommunity.testimonials.href')}
              >
                <OverviewCard.Title size="lg">
                  {t('homePage.joinCommunity.testimonials.title')}
                </OverviewCard.Title>
                <OverviewCard.Description className="mt-6">
                  {t('homePage.joinCommunity.testimonials.description')}
                </OverviewCard.Description>
                <OverviewCard.Cta className="mt-3">
                  {t('homePage.joinCommunity.testimonials.cta')}
                  <CWIcon
                    className="ml-1.5"
                    name="ArrowNarrowRightSolid"
                    size={24}
                  />
                </OverviewCard.Cta>
              </OverviewCard>
              <OverviewCard>
                <OverviewCard.Title size="lg">
                  {t('homePage.joinCommunity.followUs.title')}
                </OverviewCard.Title>
                <div className="flex flex-col gap-3 mt-6 justify-between">
                  <TextLink
                    href={t('homePage.joinCommunity.followUs.discord.href')}
                    target="_blank"
                    variant="primary"
                  >
                    <Image
                      alt={t('homePage.joinCommunity.followUs.discord.label')}
                      height={24}
                      src="/images/DiscordBlackIcon.svg"
                      width={36}
                    />
                    {t('homePage.joinCommunity.followUs.discord.label')}
                  </TextLink>
                  <TextLink
                    href={t('homePage.joinCommunity.followUs.youtube.href')}
                    target="_blank"
                    variant="primary"
                  >
                    <Image
                      alt={t('homePage.joinCommunity.followUs.youtube.label')}
                      className="mx-1.5"
                      height={16}
                      src="/images/YoutubeBlackIcon.svg"
                      width={23}
                    />
                    {t('homePage.joinCommunity.followUs.youtube.label')}
                  </TextLink>
                  <TextLink
                    href={t('homePage.joinCommunity.followUs.twitter.href')}
                    target="_blank"
                    variant="primary"
                  >
                    <Image
                      alt={t('homePage.joinCommunity.followUs.twitter.label')}
                      height={24}
                      src="/images/TwitterBlackIcon.svg"
                      width={36}
                    />
                    {t('homePage.joinCommunity.followUs.twitter.label')}
                  </TextLink>
                </div>
              </OverviewCard>
              {/* updates card here */}
              {/* <OverviewCard className="col-span-2">
                <OverviewCard.Title size="lg">Updates</OverviewCard.Title>
              </OverviewCard> */}
            </div>

            <div className="flex-1 flex items-center justify-center">
              <HeroBanner.Media
                alt={t('homePage.joinCommunity.followUs.title')}
                className="max-w-[359px]"
                src="/images/Freedom.svg"
              />
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}
